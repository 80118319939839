









































































/* the >>> is used for v-html styles */
.rc-message[data-v-2628ba18] p {
  margin: 0;
}
.rc-message[data-v-2628ba18] a {
  text-decoration: none;
  color: #00b3e3;
}
.rc-message[data-v-2628ba18] a:hover {
  text-decoration: none;
}
.slide-fade-enter-active[data-v-2628ba18] {
  transition: all 0.3s ease;
}
.slide-fade-leave-active[data-v-2628ba18] {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter[data-v-2628ba18], .slide-fade-leave-to[data-v-2628ba18]
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(80vw);
  opacity: 0;
}
