
.rc-close[data-v-3f3ef3d0] {
  height: 18px;
  width: 18px;
}
.rc-close-btn[data-v-3f3ef3d0] {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.brexit-content[data-v-3f3ef3d0]{
  padding-top: 50px;
}
