
.pos-header[data-v-5cc4944e] {
  z-index: 3;
  width: 100%;
  position: absolute;
}
.pos-header-hide[data-v-5cc4944e] {
  display: none;
}
header[data-v-5cc4944e] {
  padding: 1rem 2rem;
  background-color: rgba(0,0,0,0.5);
}
h1[data-v-5cc4944e] {
  margin: 0;
}
img[data-v-5cc4944e] {
  height: 41px;
}
