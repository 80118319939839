
@media (min-width: 1280px) {
.rc-max-width[data-v-1037774e] {
    max-width: 800px;
}
}
aside[data-v-1037774e] {
  z-index: 9997;
}
.rc-bg[data-v-1037774e] {
  z-index: 9996;
}
.slide-enter-active[data-v-1037774e],
.slide-leave-active[data-v-1037774e] {
  transition: all 0.5s;
  transform: translateX(0%);
}
.slide-enter[data-v-1037774e],
.slide-leave-to[data-v-1037774e] {
  transform: translateX(100%);
}
.rc-loader[data-v-1037774e] {
  animation: spin-data-v-1037774e 2s linear infinite;
}
@keyframes spin-data-v-1037774e {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}
