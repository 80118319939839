
#modal-bg[data-v-153e7906] {
  z-index: 9997;
  background-color: rgba(0, 0, 0, 0.4);
}
article[data-v-153e7906] {
  top: 50%;
  transform: translateY(-50%);
}
.fade-enter-active[data-v-153e7906],
.fade-leave-active[data-v-153e7906] {
  transition: opacity 0.5s;
}
.fade-enter[data-v-153e7906],
.fade-leave-to[data-v-153e7906] {
  opacity: 0;
}
.modal-body[data-v-153e7906] {
  height: calc(100% - 4.25rem);
}
