
.drop-enter-active[data-v-2da1e39c] {
  transition: all 0.5s ease;
}
.drop-leave-active[data-v-2da1e39c] {
  transition: all 0.01s;
}
.drop-enter[data-v-2da1e39c] {
  transform: translate(0, -100%);
}
.drop-leave-to[data-v-2da1e39c] {
  transform: translate(0, -100%);
}
.slide-enter-active[data-v-2da1e39c] {
  transition: all 0.5s ease-out;
}
.slide-leave-active[data-v-2da1e39c] {
  transition: all 0.5s ease;
}
.slide-enter[data-v-2da1e39c] {
  transform: translate(-100%, 0);
}
.slide-leave-to[data-v-2da1e39c] {
  transform: translate(-100%, 0);
}
@media (min-width: 1024px) {
.rc-hidden-lg[data-v-2da1e39c] {
    display: none;
}
}
