
.rotate-180[data-v-01271852] {
  transform: rotate(180deg);
}
.rc-hidden[data-v-01271852] {
  display: flex;
}
@media (min-width: 1024px) {
.rc-hidden[data-v-01271852] {
    display: none;
}
}
.dropdown-enter-active[data-v-01271852] {
  transition: all 0.4s ease;
}
.dropdown-leave-active[data-v-01271852] {
  transition: all 0.01s;
}
.dropdown-enter[data-v-01271852] {
  transform: translate(0, -100%);
}
.dropdown-leave-to[data-v-01271852] {
  transform: translate(0, -100%);
}
