
.fade-enter[data-v-54788c72],
.fade-leave-active[data-v-54788c72] {
  opacity: 0;
}
.fade-enter-active[data-v-54788c72],
.fade-leave-active[data-v-54788c72] {
  transform-style: ease;
  transition-duration: 0.5s;
  transition-property: opacity;
}
