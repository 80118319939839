
.rc-z[data-v-7f7c35c6] {
  z-index: 1000;
}
.rc-hidden[data-v-7f7c35c6] {
  display: none;
}
@media (min-width: 1024px) {
.rc-hidden[data-v-7f7c35c6] {
    display: block;
}
}
